import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import { incampusCommute } from "../../data/student's-corner"
import SEO from "../../components/seo"
import { getWords } from "../../helper-functions"
function IncampusCommute(props) {
  return (
    <div>
      <Layout>
        <SEO
          title={incampusCommute.title}
          description={getWords(incampusCommute.content, 60)}
        />
        <GeneralComp {...incampusCommute} />
      </Layout>
    </div>
  )
}

export default IncampusCommute
